/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong"
  }, _provideComponents(), props.components), {VideoIframe, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "what-are-hearing-aid-domes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-hearing-aid-domes",
    "aria-label": "what are hearing aid domes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are hearing aid domes?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Today’s ", React.createElement(_components.a, {
    href: "/hearing-aids/types/bte/",
    className: "c-md-a"
  }, "behind-the-ear (BTE) hearing aids"), " can do amazing things, such as stream music, automatically adjust to your surroundings, and produce crystal-clear sound to help you hear your best. There are many integral parts responsible for providing the clearest sound, including the silicone or plastic piece at the end that goes inside your ear canal. This piece is a hearing aid dome (or tip), and it plays an important role in your hearing experience and comfort. It protects the small speaker that sits and sends sound deep in your ear."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-that-use-hearing-aid-domes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-that-use-hearing-aid-domes",
    "aria-label": "hearing aids that use hearing aid domes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids that use hearing aid domes"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Behind-the-ear (BTE) hearing aids — known as receiver in the canal (RIC) or receiver in the ear (RITE) — use hearing aid domes. They have a small wire or tube that connects the main part of the device (which houses the microphone and processor) to the dome at the other end. The main part sits behind your ear. The thin tube with the dome then lays over top of your ear, next to your head, and disappears inside your ear."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "different-types-of-hearing-aid-domes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#different-types-of-hearing-aid-domes",
    "aria-label": "different types of hearing aid domes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Different types of hearing aid domes"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are three types of hearing aid domes:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Open dome"), ": An “open” hearing aid dome is usually best if you have mild to moderate hearing loss. Open domes provide what’s called an “open fit,” meaning they have vents that allow airflow and low-frequency natural sounds to still enter your ear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Closed dome"), ": A “closed” dome is best if you have moderate to severe hearing loss and need some lower frequencies amplified. A closed dome seals the ear canal a bit more and will still allow some air flow, but ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/background-noise-reduction/",
    className: "c-md-a"
  }, "reduces the amount of background noise"), " that comes through."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Power dome"), ": The “power” dome is best if you have severe hearing loss and need both low and high frequency sounds amplified. It completely seals your ear canal to eliminate distracting background noise completely."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-choose-the-right-hearing-aid-domes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-choose-the-right-hearing-aid-domes",
    "aria-label": "how to choose the right hearing aid domes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to choose the right hearing aid domes"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Even though hearing aid domes are most recognized as being bell-shaped, different shapes and sizes are available to fit different sizes of ear canals. Afterall, your ear is as unique as your fingerprint!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re using an over-the-counter (OTC) hearing aid, your OTC kit will normally come with a variety of tips for you to choose from. If you’ve purchased a prescription-grade hearing aid, your hearing specialist will help determine the proper size domes for your ear, based on width and length measurements taken during your appointment."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-change-hearing-aid-domes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-change-hearing-aid-domes",
    "aria-label": "how to change hearing aid domes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to change hearing aid domes"), "\n", React.createElement(VideoIframe, {
    title: "Hear.com Tips and Tricks | How to Change Your Dome",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/7ypPFXmPHKM?si=JHgvU6ZfM-qiwmZA"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "talk-with-a-hearing-aid-specialist",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#talk-with-a-hearing-aid-specialist",
    "aria-label": "talk with a hearing aid specialist permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Talk with a hearing aid specialist"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Before you purchase a hearing aid, talk with a hearing aid specialist and schedule a professional hearing test. Understanding your level of hearing loss will help you determine the best solution for your hearing and your life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "Hear.com"), " can help you navigate the world of hearing aids. Answer a few short questions by clicking below to get started today. You may qualify for a no-cost, no-pressure hearing aid trial to try the best hearing aids on the market for 45 days."), "\n", React.createElement(LandingPageCta, {
    copy: "Fill out our Questionnaire",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
